import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { GenericService } from 'src/app/@Core/services/generic.service';
import { configApiUrl } from 'src/app/app-const';

@Injectable({
  providedIn: 'root',
})
export class AgentService extends GenericService<any> {
  constructor(http: HttpClient, router: Router) {
    super(http, configApiUrl.AGENT_API, router);
  }
  getCommissionByAgent(agentId, serviceCode){
    return this.http.get(this.baseUrl + '/getCommission/' + agentId + '/' + serviceCode, {observe: 'response'}).pipe(
      map((res: any) => {
          this.setUpdatedHeader(res);
          return res.body;
      }),
      catchError((error) => this.handleError(error))
    );
  }

  getCommissionByAgentAndCountry(agentId, serviceCode, country){
    return this.http.get(this.baseUrl + '/getCommission/' + agentId + '/' + serviceCode + '/' + country, {observe: 'response'}).pipe(
        map((res: any) => {
          this.setUpdatedHeader(res);
          return res.body;
        }),
        catchError((error) => this.handleError(error))
    );
  }
  getAgentCreditReportsById(agentId){
    return this.http.get(this.baseUrl + '/agentCreditReport/' + agentId, {observe: 'response'}).pipe(
      map((res: any) => {
          this.setUpdatedHeader(res);
          return res.body;
      }),
      catchError((error) => this.handleError(error))
  );
  }
  getAgentCreditDetails(){
    return this.http.get(this.baseUrl + '/agentCreditDetail', {observe: 'response'}).pipe(
      map((res: any) => {
          this.setUpdatedHeader(res);
          return res.body;
      }),
      catchError((error) => this.handleError(error))
  );
  }
  postAgentCreditPayment(model){
    return this.http.post(this.baseUrl + '/creditPayment', model, {observe: 'response'}).pipe(
      map((res: any) => {
          this.setUpdatedHeader(res);
          return res.body;
      }),
      catchError((error) => this.handleError(error))
  );
  }

  getTopAgentCreditUsage(){
    return this.http.get(this.baseUrl + '/topAgentCreditUsage', {observe: 'response'}).pipe(
      map((res: any) => {
          this.setUpdatedHeader(res);
          return res.body;
      }),
      catchError((error) => this.handleError(error))
  );
  }
  getSelfAgentCreditUsage(){
    return this.http.get(this.baseUrl + '/selfAgentCreditReport', {observe: 'response'}).pipe(
      map((res: any) => {
          this.setUpdatedHeader(res);
          return res.body;
      }),
      catchError((error) => this.handleError(error))
  );
  }
}

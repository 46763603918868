import { Component, Inject, ViewChild, EventEmitter, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NZ_MODAL_DATA, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { BallonServiceCode, CountryList } from 'src/app/app-const';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BookingService } from '../service/booking.service';
import { jwtDecode } from 'jwt-decode';
import { DiscountService } from '../../setting/discount/service/discount.service';
import { UserService } from '../../user/service/user.service';
import { AgentService } from '../../agent/service/agent.service';
import { DatePipe } from '@angular/common';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmBookingComponent } from '../dialog-schedule-detail/confirm-booking/confirm-booking.component';
import { DetailDialogComponent } from '../dialog-schedule-detail/detail-dialog/detail-dialog.component';
import { RefundDetailDialogComponent } from '../dialog-schedule-detail/refund-detail-dialog/refund-detail-dialog.component';


@Component({
  selector: 'app-dialog-booking',
  templateUrl: './dialog-booking.component.html',
  styleUrls: ['./dialog-booking.component.css'],
})
export class DialogBookingComponent {
  @Output() actionOccurred = new EventEmitter<boolean>();
  @ViewChild('validationForm', { static: true }) validationForm!: NgForm;
  image: any = '';
  imageSize: any;
  imageFileName = '';
  countrylist = CountryList;
  model = {
    categoryId: null,
    scheduleId: null,
    bookingDate: null,
    paymentDetail: {
      paymentStatus: 'unpaid',
      paymentAuthorizedBy: null,
      totalAmount: null,
      discount: null,
      discountAuthorizedBy: null,
      agentCommission: null,
      netAmount: null,
      paidAmount: null,
      agentId: null,
      discountDetail: {
        name: null,
        discountType: null,
        discountValue: null,
      },
      agentCommissionDetail: {
        commissionType: null,
        commissionValue: null,
      },
      exchangeRateDetail: {
        currency: null,
        rate: null,
        amount: null,
      },
    },
    customerDetail: {
      fullName: null,
      email: null,
      pickUpLocation: null,
      dateOfBirth: null,
      country: null,
      gender: null,
      contactNumber: null,
      weight: null,
    },
  };

  discountList: Array<any> = [];
  userList: Array<any> = [];

  customerDoc: any;

  tokenDetail: any = jwtDecode(localStorage.getItem('token'));

  submitClicked = false;
  cancelObj: any;
  defaultPickerValue: any
  minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 3));
  disabledDate = (current: Date): boolean => {
    return current && current > this.minDate; // Disable all dates before minDate
  };

  constructor(
    private modal: NzModalRef,
    @Inject(NZ_MODAL_DATA) public data: any,
    private message: NzMessageService,
    private service: BookingService,
    private discountService: DiscountService,
    private userService: UserService,
    private agentService: AgentService,
    private datePipe: DatePipe,
    private notification: NzNotificationService,
    private loadingService: NgxSpinnerService,
    public modalService: NzModalService
  ) {
    this.defaultPickerValue = this.minDate;
    this.model.bookingDate = this.data.date;
    console.log(this.tokenDetail, this.data.date, this.data);

    this.model.paymentDetail.agentId = this.tokenDetail.agentId;
    this.model.scheduleId = this.data?.scheduleID;
    this.model.categoryId = this.data?.categoryId;

    if (this.data?.bookingData) {
      console.log(this.data?.bookingData);
      this.model.customerDetail = this.data?.bookingData.customerDetail;
      this.model.paymentDetail = this.data?.bookingData.paymentDetail;

      if (this.data?.bookingData?.customerDetail?.registrationDocumentId) {
        this.service.getDocumentById(this.data?.bookingData?.customerDetail?.registrationDocumentId)
          .subscribe((resImg) => {
            this.service.convertBlobToBase64(resImg).then(base64 => {
              this.image = base64;
            });
          })
      }
    }

    if (
      !this.model.paymentDetail.agentId ||
      this.model.paymentDetail.agentId == ''
    ) {
      this.getDiscountList();
      this.getUserList();
    } else {
      this.getCommission();
    }
    console.log(this.model);
  }

  getDiscountList(reset: boolean = false, value?) {
    let params = {
      pageNumber: 1,
      pageSize: 100,
    };
    this.discountService.getSearchList(params).subscribe(
      (data: any) => {
        // this.loadingService.hide()
        this.discountList = data.data.data;
      },
      (error) => {
        // this.loadingService.hide()
        console.log(' list error', error);
      }
    );
  }

  getUserList(reset: boolean = false, value?) {
    let params = {
      pageNumber: 1,
      pageSize: 100,
      searchFilters: [{ field: 'role', value: 'ADMIN' }],
    };
    this.userService.getSearchList(params).subscribe(
      (data: any) => {
        // this.loadingService.hide()
        this.userList = data.data.data;
      },
      (error) => {
        // this.loadingService.hide()
        console.log(' list error', error);
      }
    );
  }

  getCommission() {
    this.agentService
      .getCommissionByAgent(
        this.model.paymentDetail.agentId,
        BallonServiceCode.ballon_default
      )
      .subscribe((data: any) => {
        if (data) {
          const resp = data?.data;
          this.model.paymentDetail.agentCommissionDetail = resp;
        }
      });
  }

  destroyModal(): void {
    this.modal.destroy();
  }

  onFileChange(event: any) {
    let targetFiles;
    if (event.target?.files?.length) {
      targetFiles = event.target.files;
    } else if (event.dataTransfer.files) {
      targetFiles = event.dataTransfer.files;
    } else {
      return;
    }
    if(targetFiles[0].size <= 500000){
    this.imageSize = targetFiles[0].size;
    this.imageFileName = targetFiles[0].name;

    this.customerDoc = targetFiles[0];

    var reader = new FileReader();

    reader.readAsDataURL(targetFiles[0]); // read file as data url
    let img;
    reader.onload = (event) => {
      // called once readAsDataURL is completed
      img = event.target?.result;
      this.image = img;
    };
  }else{
    this.notification.error("Error", "File size should be less than 500 Kb");
  }

  }

  getPriceByCountry() {
    if(!this.data?.bookingData){
      this.model.paymentDetail.paymentStatus ="PAID";
    }
    this.service
      .getPriceByCountry(
        this.model.customerDetail.country,
        BallonServiceCode.ballon_default
      )
      .subscribe((data: any) => {
        if (data) {
          const resp = data?.data;
          this.model.paymentDetail.exchangeRateDetail.amount = resp.price;
          this.model.paymentDetail.exchangeRateDetail.currency = resp.currency;
          this.model.paymentDetail.exchangeRateDetail.rate = resp.conversionRate;
          if (this.model.paymentDetail.exchangeRateDetail.rate) {
            this.model.paymentDetail.totalAmount =
              resp.price * resp.conversionRate;
            if (
              this.model.paymentDetail.agentId &&
              this.model.paymentDetail.agentId != ''
            ) {
              this.model.paymentDetail.agentCommission =
                this.calculateCommission(
                  this.model.paymentDetail.totalAmount,
                  this.model.paymentDetail.agentCommissionDetail.commissionType,
                  this.model.paymentDetail.agentCommissionDetail.commissionValue
                );
            }
            this.model.paymentDetail.netAmount =
              (this.model.paymentDetail.totalAmount -
              Number(this.model.paymentDetail.discount) -
              Number(this.model.paymentDetail.agentCommission)).toFixed(2);
            if (this.model.paymentDetail.paymentStatus == 'PAID') {
              this.model.paymentDetail.paidAmount =
                this.model.paymentDetail.netAmount;
            } else {
              this.model.paymentDetail.paidAmount = 0;
            }
          } else {
            this.model.paymentDetail.totalAmount = resp.price;
            if (
              this.model.paymentDetail.agentId &&
              this.model.paymentDetail.agentId != ''
            ) {
              this.model.paymentDetail.agentCommission =
                this.calculateCommission(
                  this.model.paymentDetail.totalAmount,
                  this.model.paymentDetail.agentCommissionDetail.commissionType,
                  this.model.paymentDetail.agentCommissionDetail.commissionValue
                );
            }
            this.model.paymentDetail.netAmount =
              this.model.paymentDetail.totalAmount -
              Number(this.model.paymentDetail.discount) -
              Number(this.model.paymentDetail.agentCommission);
            if (this.model.paymentDetail.paymentStatus == 'PAID') {
              this.model.paymentDetail.paidAmount =
                this.model.paymentDetail.netAmount;
            } else {
              this.model.paymentDetail.paidAmount = 0;
            }
          }
        }
        if (this.model.paymentDetail.discountDetail.discountValue) {
          this.discountChange(this.model.paymentDetail.discountDetail);
        }
      });
  }

  submit() {
    this.submitClicked = true;
    if (this.validationForm?.valid && this.customerDoc) {
      let pipeDate = this.datePipe.transform(new Date(this.data.date), 'yyyy-MM-ddTHH:mm:ss.SSS')
      this.model.bookingDate = pipeDate;
      const formData = new FormData();
      formData.append('bookingDetailRequest', JSON.stringify(this.model));
      formData.append('document', this.customerDoc);
      this.loadingService.show();
      this.service.save(formData).subscribe(
        (resp: any) => {
          this.notification.success('Success', resp.message);
          this.loadingService.hide();
          this.service.setCurrentList("details-list");
          this.modal.destroy(true);
        },
        (err) => {
          this.notification.error('Error', err.error.message);
          this.loadingService.hide();
        }
      );
    } else {
      Object.keys(this.validationForm.controls).forEach((key) => {
        this.validationForm?.controls[key].markAsDirty();
        this.validationForm?.controls[key].updateValueAndValidity();
        this.validationForm?.controls[key].markAsTouched();
      });
    }
  }

  discountChange(value: any) {
    this.model.paymentDetail.discount = 0;
    this.model.paymentDetail.discountDetail = value;

    if (this.model.paymentDetail.discountDetail){
     this.model.paymentDetail.discount = this.calculateCommission(
         this.model.paymentDetail.totalAmount,
         this.model.paymentDetail.discountDetail.discountType,
         this.model.paymentDetail.discountDetail.discountValue
     );
    }

    this.model.paymentDetail.netAmount =
        ( this.model.paymentDetail.totalAmount -
      Number(this.model.paymentDetail.discount) -
      Number(this.model.paymentDetail.agentCommission)).toFixed(2);

    if (this.model.paymentDetail.paymentStatus == 'PAID') {
      this.model.paymentDetail.paidAmount = this.model.paymentDetail.netAmount;
    } else {
      this.model.paymentDetail.paidAmount = 0;
    }
  }

  paymentStatusChange(value: any) {
    this.model.paymentDetail.paidAmount = 0;
    if (value == 'PAID') {
      this.model.paymentDetail.paidAmount = this.model.paymentDetail.netAmount;
    }
  }

  calculateCommission(
    baseAmount: number,
    commissionType: 'flat' | 'percentage',
    commissionValue: number
  ): number {
    let commission: number;

    if (commissionType.toLocaleLowerCase() === 'flat') {
      commission = commissionValue;
    } else if (commissionType.toLocaleLowerCase() === 'percentage') {
      commission = (baseAmount * commissionValue) / 100;
    } else {
      throw new Error(
        'Invalid commission type. Must be either "flat" or "percentage".'
      );
    }

    return commission;
  }

  closeModelAndEmitEvent(): void{
    this.destroyModal();
    this.actionOccurred.emit(true);
  }

  cancelBooking(type: string, id: any = null) {
    const modalRef: NzModalRef = this.modalService.create({
      nzTitle: 'Schedule Detail',
      nzWidth: '500px',
      nzData: {
        type: 'single',
        selectedSchedule: 0
      },
      nzContent: DetailDialogComponent
    });

    modalRef.afterClose.subscribe(result => {
      if (result) {
        this.cancelObj = result;
        this.cancelConfirm(type, id);
      }
    });
  }

  cancelConfirm(type: any, id: any) {
    const modalRef: NzModalRef = this.modalService.confirm({
      nzTitle: 'Warning',
      nzWidth: '500px',
      nzData: {
        title: 'Are you Sure? You want to cancel Schedule'
      },
      nzContent: ConfirmBookingComponent,
      nzOnOk: (val: any) => {

        let params = {
          cancelType: this.cancelObj.cancelledBy,
          cancelReason: this.cancelObj.cancelReason,
        }


        this.service.cancelBookingDetail(params, id)
          .subscribe(
            (resp: any) => {
              if (resp?.data) {
                this.notification.success('success', resp.message);
                // this.getDetail();
                // parentModalRef.destroy(true);
                this.closeModelAndEmitEvent();
              }
            },
            err => {
              this.notification.error('error', err.error.message);
            }
          )
      },
      nzOnCancel: () => {
        console.log('Delete action canceled');
      }
    });
  }

  getDetail() {
    this.service.getBookingDetail(this.data?.bookingData?.id)
      .subscribe(
        (resp: any) => {
          if (resp?.data) {
            this.data.bookingData = resp.data;
          }
        },
        err => {
          this.notification.error('error', err.error.message);
        }
      )
  }

  refundDetail(id) {
    event.preventDefault();
    event.stopPropagation();
    this.service.getRefundDetail(id)
      .subscribe(
        (resp: any) => {
          if (resp?.data) {
            const modalRef: NzModalRef = this.modalService.create({
              nzTitle: 'Refund',
              nzWidth: '500px',
              nzData: {
                data: resp.data
              },
              nzContent: RefundDetailDialogComponent
            });

            modalRef.afterClose.subscribe(result => {
              if (result == true) {
                this.refundConfirm(id);
              }
            });
          }
        },
        err => {
          this.notification.error('error', err.error.message);
        }
      )


  }

  refundConfirm(id: any, payment: any = null) {
    let title =  'Are you Sure? You want to Refund';
    if(payment){
      title = 'Are you Sure? You want to mark as Paid';
    }
    const modalRef: NzModalRef = this.modalService.confirm({
      nzTitle: 'Warning',
      nzWidth: '500px',

      nzData: {
        title: title
      },
      nzContent: ConfirmBookingComponent,
      nzOnOk: (val: any) => {

        if (payment) {
          this.service.getMarkAsPaid(id)
          .subscribe(
            (resp: any) => {
              if (resp?.data) {
                this.notification.success('success', resp.message);
                this.closeModelAndEmitEvent();
              }
            },
            err => {
              this.notification.error('error', err.error.message);
            }
          )
        }

        else {

          this.service.getRefund(id)
            .subscribe(
              (resp: any) => {
                if (resp?.data) {
                  this.notification.success('success', resp.message);
                  this.closeModelAndEmitEvent();
                }
              },
              err => {
                this.notification.error('error', err.error.message);
              }
            )
        }

      },
      nzOnCancel: () => {
        console.log('Delete action canceled');
      }
    });
  }

  payDetail(id) {
    event.preventDefault();
    event.stopPropagation();
    this.service.getPaymentDetail(id)
      .subscribe(
        (resp: any) => {
          if (resp?.data) {
            const modalRef: NzModalRef = this.modalService.create({
              nzTitle: 'Payment Detail',
              nzWidth: '500px',
              nzData: {
                data: resp.data
              },
              nzContent: RefundDetailDialogComponent
            });

            modalRef.afterClose.subscribe(result => {
              if (result == true) {
                this.refundConfirm(id, 'payment');
              }
            });
          }
        },
        err => {
          this.notification.error('error', err.error.message);
        }
      )


  }


}

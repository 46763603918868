<div class="common-layout {{ 'is-' + selectedHeaderColor }}"
    [ngClass]="{
        'is-folded': isFolded,
        'is-side-nav-dark' : isSideNavDark,
        'is-expand' : isExpand
    }"
>
    <app-header></app-header>
    <app-sidenav></app-sidenav>
    <div class="page-container">
        <div class="main-content" style="margin-bottom: 10px;padding-left: 10px;padding-right: 10px;">
            <div class="main-content-header" style="padding-left: 10px;" >
                <nz-breadcrumb nzSeparator=">">
                    <i class="m-r-5 text-gray" nz-icon nzType="home"></i>
                    <nz-breadcrumb-item *ngFor="let breadcrumb of breadcrumbs$ | async">
                        <a (click)="goToRoute(breadcrumb.url)">
                            {{ breadcrumb.label }}
                        </a>
                    </nz-breadcrumb-item>
                </nz-breadcrumb>
            </div>
            <div style="margin-top: -15px;">
                <router-outlet  #o="outlet"></router-outlet>
            </div>
        </div>
        <app-footer></app-footer>
    </div>
</div>

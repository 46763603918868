import { Directive, HostListener, ElementRef, Input, Renderer2, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appNumberInput]'
})
export class NumberInputDirective {

  @Input() isPercent: boolean = false; 
  @Input() allowDecimals: boolean = false; 
  constructor(private el: ElementRef, private renderer: Renderer2, @Optional() private ngControl: NgControl) { }
  @HostListener('input', ['$event'])
  onInputChange(event: Event): void {
    const input = this.el.nativeElement as HTMLInputElement;
    let value = input.value;
    if (this.isPercent) {
      value = value.replace(/[^0-9.]/g, '');
      const parts = value.split('.');
      if (parts.length > 2) {
        value = `${parts[0]}.${parts[1]}`;
      }

      if (parts[1] && parts[1].length > 2) {
        value = `${parts[0]}.${parts[1].substring(0, 2)}`;
      }
      let numericValue = parseFloat(value);
      if (numericValue > 100) {
        value = ''; 
      } else if (numericValue < 0) {
        value = '0';
      }
      this.updateValue(value);
    } else if (this.allowDecimals) {
      value = value.replace(/[^0-9.]/g, '');
      const parts = value.split('.');
      if (parts.length > 2) {
        value = `${parts[0]}.${parts[1]}`;
      }
      if (parts[1] && parts[1].length > 2) {
        value = `${parts[0]}.${parts[1].substring(0, 2)}`;
      }
      this.updateValue(value);
    } else {
      value = value.replace(/[^0-9]/g, '');
      this.updateValue(value);
    }
  }

  private updateValue(value: string): void {
    const input = this.el.nativeElement as HTMLInputElement;
    if (value === '0' || value === '') {
      if (this.ngControl) {
        this.ngControl.control?.setValue(null);
      }
      this.renderer.setProperty(input, 'value', '');
    } else {
      this.renderer.setProperty(input, 'value', value);
      if (this.ngControl) {
        this.ngControl.control?.setValue(value);
      }
    }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    const allowedKeys = [
      'Backspace', 'ArrowLeft', 'ArrowRight', 'Tab', 'Delete',
      'Home', 'End', 'Escape', 'Enter'
    ];
    const isNumberKey = (event.key >= '0' && event.key <= '9');
    const isDecimalPoint = (event.key === '.');
    const input = this.el.nativeElement as HTMLInputElement;

    if (!allowedKeys.includes(event.key) && !isNumberKey && (!this.allowDecimals || !isDecimalPoint)) {
      event.preventDefault();
    }
    if (this.allowDecimals && isDecimalPoint && input.value.length === 0) {
      event.preventDefault();
    }
    if (this.allowDecimals && isDecimalPoint && input.value.includes('.')) {
      event.preventDefault();
    }
  }

  @HostListener('submit', ['$event'])
  onFormSubmit(event: Event): void {
    const input = this.el.nativeElement as HTMLInputElement;
    const value = input.value;
    if (value === '0' || value === '') {
      event.preventDefault();
    }
  }
}

import { Directive, ElementRef, forwardRef, HostListener } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Directive({
  selector: '[appTrim]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TrimDirective),
      multi: true
    }
  ]
})
export class TrimDirective implements ControlValueAccessor {
  private onChange: (value: string) => void;
  private onTouched: () => void;

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event.target.value'])
  onInput(value: any) {
    if (value !== null && value !== undefined) {
      const sanitizedValue = this.sanitizeInput(value);
      this.el.nativeElement.value = sanitizedValue;
      this.onChange(sanitizedValue);
    }
  }

  @HostListener('blur')
  onBlur() {
    const value = this.el.nativeElement.value;
    const sanitizedValue = this.sanitizeInput(value);
    this.el.nativeElement.value = sanitizedValue;
    this.onChange(sanitizedValue);
    this.onTouched();
  }

  writeValue(value: any): void {
    const sanitizedValue = this.sanitizeInput(value || '');
    this.el.nativeElement.value = sanitizedValue;
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.el.nativeElement.disabled = isDisabled;
  }

  private sanitizeInput(value: any): string {
    const stringValue = String(value); // Ensure value is a string
    const whitespaceRegex = /^[\s\t]*$/;
    if (whitespaceRegex.test(stringValue)) {
      return '';
    }
    return stringValue;
  }
}

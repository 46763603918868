import {Injectable, TemplateRef, ViewChild} from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable({
    providedIn: 'root'
})
export class NotificationService  {
    @ViewChild('successNotification', { static: true }) successNotification!: TemplateRef<any>;
    constructor(private notificationService: NzNotificationService) {
    }
    
    successNotify(){
      
    //   <nz-alert
    //     nzType="info"
    //     nzMessage="Informational Notes"
    //     nzDescription="Additional description and informations about copywriting."
    //     nzShowIcon
    //   ></nz-alert>
    //   <nz-alert
    //     nzType="warning"
    //     nzMessage="Warning"
    //     nzDescription="This is a warning notice about copywriting."
    //     nzShowIcon
    //   ></nz-alert>
    //   <nz-alert
    //     nzType="error"
    //     nzMessage="Error"
    //     nzDescription="This is an error message about copywriting."
    //     nzShowIcon
    //   ></nz-alert> 
        // this.notificationService.template(
        //     <nz-alert
        //     nzType="success"
        //     nzMessage="Success Tips"
        //     nzDescription="Detailed description and advices about successful copywriting."
        //     nzShowIcon
        //   ></nz-alert>
        // )
    }

    errorNotify(title: string = 'Error', description: string){
        this.notificationService.error(
            title,
            description,
            {nzClass:'notify-error'}
          );
        }
    
}
<div class="header">
  <div class="logo logo-dark">
    <a href="">
      <img style="height: 70px;" src="assets/images/logo/logo.png" alt="Logo">
      <img style="
            height: 50px;
            margin: 9px auto;
        " class="logo-fold" src="assets/images/logo/logo.png" alt="Logo">
    </a>
  </div>
  <div class="logo logo-white" style="background-color: #fff;">
    <a href="">
      <img style="height: 70px;" src="assets/images/logo/logo.png" alt="Logo">
      <img style="
            height: 50px;
            margin: 9px auto;
        " class="logo-fold" src="assets/images/logo/logo.png" alt="Logo">
    </a>
  </div>
  <div class="nav-wrap">
    <ul class="nav-left">
      <li class="desktop-toggle">
        <a (click)="toggleFold()">
          <i nz-icon [nzType]="isFolded? 'menu-unfold':'menu-fold'" theme="outline"></i>
        </a>
      </li>
      <li class="mobile-toggle">
        <a (click)="toggleExpand()">
          <i nz-icon [nzType]="isExpand? 'menu-fold': 'menu-unfold'" theme="outline"></i>
        </a>
      </li>
    </ul>
    <ul class="nav-right">
      <li *ngIf="agentCreditDetails && tokenDetail.role =='AGENT'">
        <nz-tag [nzColor]="''">Credit:
          <span>{{agentCreditDetails.agentCreditUsage.creditUsageAmount}}</span>/<span>{{agentCreditDetails.agentCredit.creditAmount}}</span>
        </nz-tag>
      </li>
      <li>
        <nz-tag [nzColor]="'#87d068'">{{tokenDetail.role}}</nz-tag>
      </li>
      <li>
      <span class="p-h-10 pointer" nz-dropdown [nzDropdownMenu]="profile"
            [nzTrigger]="'click'" [nzPlacement]="'bottomRight'">
          <nz-avatar [nzIcon]="'user'" nzSrc="assets/images/avatars/thumb-3.jpg"></nz-avatar>
      </span>
        <nz-dropdown-menu #profile="nzDropdownMenu">
          <ul nz-menu class="p-b-15 p-t-20">
            <li class="p-h-20 p-b-15 m-b-10 border-bottom">
              <div class="d-flex m-r-50 align-items-center">
                <div class="m-l-10">
                  <p class="m-b-0 text-dark font-weight-semibold">{{profileDetails?.firstName}} {{profileDetails?.lastName}}</p>
                  <p class="m-b-0 opacity-07">{{profileDetails?.email}}</p>
                </div>
              </div>
            </li>
            <li nz-menu-item (click)="showChangePassword()">
              <span class="p-v-5 d-flex align-items-center justify-content-between">
                  <div>
                      <i class="opacity-04 font-size-16" nz-icon nzType="lock"
                         theme="outline"></i>
                      <span class="m-l-10">Change Password</span>
                  </div>

              </span>
            </li>


            <li nz-menu-item>
              <span (click)="logout()" class="p-v-5 d-flex align-items-center justify-content-between">
                  <div>
                      <i class="opacity-04 font-size-16" nz-icon nzType="logout"
                         theme="outline"></i>
                      <span class="m-l-10">Logout</span>
                  </div>

              </span>
            </li>
          </ul>
        </nz-dropdown-menu>
      </li>

    </ul>
  </div>
</div>

<nz-modal [nzStyle]="{ top: '20px' ,width: showPasswordModal ? '500px':'500px' }" [nzVisible]="showPasswordModal"
          [nzFooter]="modalFooter"
          [nzCloseIcon]="null"
          [nzMaskClosable]="false"
          [nzTitle]="'Change Password'"
          (nzOnCancel)="closePasswordModel()">
  <ng-container *nzModalContent>
    <div class="col-md-12" *ngIf="showPasswordModal">
      <form nz-form  [formGroup]="changePasswordForm" nzLayout="vertical" class="login-form"  >
        <div class="row">
          <div class="col-md-12">
            <label class="label ">Old Password</label>
            <nz-form-item>
              <nz-form-control nzErrorTip="Please enter your old Password!">
                <nz-input-group [nzPrefix]="prefixLock" [nzSuffix]="suffixTemplate2">
                  <input   [type]="passwordVisible2 ? 'text' : 'password'" nz-input formControlName="oldPassword" placeholder="Old Password">
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="col-md-12">
            <label class="label ">New Password</label>
            <nz-form-item>
              <nz-form-control [nzErrorTip]="newPasswordError">
                <nz-input-group [nzPrefix]="prefixLock" [nzSuffix]="suffixTemplate">
                  <input nz-input type="password" nz-popover
                         nzPopoverPlacement="right"
                         [nzPopoverTrigger]="passwordPolicyList?.enabled ? 'focus' :''"
                         [pattern]="nameRegex"
                         [type]="passwordVisible ? 'text' : 'password'"
                         [nzPopoverContent]="passwordPolicyTemplate" formControlName="password" placeholder="New Password">
                </nz-input-group>
                <ng-template #newPasswordError  let-control>
                  <ng-container *ngIf="control.hasError('required')">Please enter your new Password!</ng-container>
                  <ng-container *ngIf="control.hasError('pattern')">
                    <ul style="padding-left: 20px;">
                      <li>Password must be at least 8 characters long</li>
                      <li>Contain at least one uppercase letter</li>
                      <li>Contain at least one number</li>
                      <li>Contain at least one special character</li>
                    </ul>
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="col-md-12">
            <label class="label ">Confirm Password</label>
            <nz-form-item>
              <nz-form-control [nzErrorTip]="confirmPasswordError">
                <nz-input-group [nzPrefix]="prefixLock" [nzSuffix]="suffixTemplate1">
                  <input nz-input
                         [type]="passwordVisible1 ? 'text' : 'password'"
                         [pattern]="changePasswordForm.controls['password'].value "
                         type="password" formControlName="rePassword" placeholder="Confirm Password">
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>
          <ng-template #confirmPasswordError  let-control>
            <ng-container *ngIf="control.hasError('required')">Please confirm password!</ng-container>
            <ng-container *ngIf="changePasswordForm.errors?.mismatch && (changePasswordForm.controls['rePassword'].dirty || changePasswordForm.controls['rePassword'].touched)">
              Password Doesn't match
            </ng-container>
          </ng-template>
          <div class="col-sm-12">
            <button style="width: 100%" nz-button nzType="primary" (click)="changePassword()">Change Password</button>
          </div>
          <ng-template #prefixLock><i nz-icon nzType="lock"></i></ng-template>
          <ng-template #suffixTemplate>
            <i nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'" (click)="passwordVisible = !passwordVisible"></i>
          </ng-template>
          <ng-template #suffixTemplate1>
            <i nz-icon [nzType]="passwordVisible1 ? 'eye-invisible' : 'eye'" (click)="passwordVisible1 = !passwordVisible1"></i>
          </ng-template>
          <ng-template #suffixTemplate2>
            <i nz-icon [nzType]="passwordVisible2 ? 'eye-invisible' : 'eye'" (click)="passwordVisible2 = !passwordVisible2"></i>
          </ng-template>
        </div>
      </form>
    </div>
  </ng-container>


  <ng-template #modalFooter>
    <button nz-button nzType="default" (click)="closePasswordModel()">Close</button>
  </ng-template>
</nz-modal>

import { Component } from '@angular/core';
import { ThemeConstantService } from '../../services/theme-constant.service';
import { Router } from '@angular/router';
import { jwtDecode } from "jwt-decode";
import { UserService } from 'src/app/pages/user/service/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AgentService } from 'src/app/pages/agent/service/agent.service';
import {BookingService} from "../../../pages/booking/service/booking.service";
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html'
})

export class HeaderComponent{
    changePasswordForm: FormGroup;
    searchVisible = false;
    quickViewVisible = false;
    isFolded: boolean;
    isExpand: boolean;
    tokenDetail: any;
    profileDetails: any;
    showProfileModal = false;
    showPasswordModal = false;
    passwordVisible = false;
    passwordVisible1 = false;
    passwordVisible2 = false;
    agentCreditDetails: any;
    constructor( private themeService: ThemeConstantService, private router: Router,
                 private notification: NzNotificationService,
                 private agentService: AgentService,
                 private fb: FormBuilder,
                 private userService: UserService,
                 private bookingService: BookingService) {
        this.changePasswordForm = this.fb.group({
            oldPassword: [ null, [ Validators.required ] ],
            password: [ null, [ Validators.required, Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[^\\w\\s])[A-Za-z\\d\\W]{8,}$') ] ],
            rePassword: [ null, [ Validators.required ] ]
          },
            {validator: this.passwordMatchValidator});

        this.userService.userProfile().subscribe(data => {
            this.profileDetails = data.data;
        });
    }

    ngOnInit(): void {
        this.tokenDetail = jwtDecode(localStorage.getItem('token'));
        this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
        this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
        if (this.tokenDetail.role == 'AGENT'){
            this.bookingService.currentList$.subscribe(dat => {
                if (dat == 'details-list') {
                    this.getAgentCreditDetails();
                }
            });
            this.getAgentCreditDetails();
        }
    }

    getAgentCreditDetails(): void {
        this.agentService.getAgentCreditDetails().subscribe(data => {
            this.agentCreditDetails = data.data;
        });
    }

    toggleFold() {
        this.isFolded = !this.isFolded;
        this.themeService.toggleFold(this.isFolded);
    }

    toggleExpand() {
        this.isFolded = false;
        this.isExpand = !this.isExpand;
        this.themeService.toggleExpand(this.isExpand);
        this.themeService.toggleFold(this.isFolded);
    }

    searchToggle(): void {
        this.searchVisible = !this.searchVisible;
    }


    notificationList = [
        {
            title: 'You received a new message',
            time: '8 min',
            icon: 'mail',
            color: 'ant-avatar-' + 'blue'
        },
        {
            title: 'New user registered',
            time: '7 hours',
            icon: 'user-add',
            color: 'ant-avatar-' + 'cyan'
        },
        {
            title: 'System Alert',
            time: '8 hours',
            icon: 'warning',
            color: 'ant-avatar-' + 'red'
        },
        {
            title: 'You have a new update',
            time: '2 days',
            icon: 'sync',
            color: 'ant-avatar-' + 'gold'
        }
    ];

    logout(){
        localStorage.clear();
        this.router.navigate(['/auth/login'])
    }

    showChangePassword(){
        this.showPasswordModal = true;
      }
    closePasswordModel(): void {
        this.showPasswordModal = false;
        this.changePasswordForm.reset();
    }
      changePassword(){
        for (const i in this.changePasswordForm.controls) {
            this.changePasswordForm.controls[ i ].markAsDirty();
            this.changePasswordForm.controls[ i ].updateValueAndValidity();
          }
          if(this.changePasswordForm.valid) {
        this.userService.changePassword(this.changePasswordForm.value).subscribe(data=>{
            this.notification.success("Success", data.message);
            this.closePasswordModel();
        }, error => {
            this.notification.error("Error", error.error.message);
          })
         } else{
            this.notification.error("Error", 'Please fill the required items');
          }
      }


      passwordMatchValidator(frm: FormGroup) {
        return frm.controls['password'].value === frm.controls['rePassword'].value ? null : {'mismatch': true};
      }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { FullLayoutComponent } from "./layouts/full-layout/full-layout.component";
import { CommonLayoutComponent } from "./layouts/common-layout/common-layout.component";

import { FullLayout_ROUTES } from "./shared/routes/full-layout.routes";
import { CommonLayout_ROUTES } from "./shared/routes/common-layout.routes";
import { AuthGuard } from './@Core/guard/auth.guard';


import { ConfirmEmailComponent } from './pages/confirm-email/user-add/confirm-email.component';

const appRoutes: Routes = [
    {
        path: '',
        redirectTo: '/dashboard/home',
        pathMatch: 'full',
    },
    {
        path: '',
        component: CommonLayoutComponent,
        children: CommonLayout_ROUTES ,
        canActivate: [AuthGuard],
    },
    {
        path: '',
        component: FullLayoutComponent,
        children: FullLayout_ROUTES
    },
    {
        path: 'confirm-email/:tokenId',
        component: ConfirmEmailComponent
    },
    {path: '**', redirectTo: '/dashboard/home'},
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
            preloadingStrategy: PreloadAllModules,
            anchorScrolling: 'enabled',
            scrollPositionRestoration: 'enabled'
        })
    ],
    exports: [
        RouterModule
    ]
})

export class AppRoutingModule {
}

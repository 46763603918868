import { Component, Inject } from '@angular/core';
import { NZ_MODAL_DATA, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { ConfirmBookingComponent } from '../confirm-booking/confirm-booking.component';

@Component({
  selector: 'app-detail-dialog',
  templateUrl: './detail-dialog.component.html',
  styleUrls: ['./detail-dialog.component.css']
})
export class DetailDialogComponent {
  model = {
    cancelledBy: '',
    cancelReason: ''
  }
  constructor(
    private modal: NzModalRef,
    @Inject(NZ_MODAL_DATA) public data: any,
    public modalService: NzModalService
  ) {
  }

  destroyModal(): void {
    if (this.data.type == 'multiple') {
      this.model.cancelledBy = 'CANCELED_BY_ADMIN'
    }
    if (this.model.cancelledBy !== '' && this.model.cancelReason !== '') {
      this.modal.destroy(this.model);
    }
  }
}

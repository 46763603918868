import {Injectable} from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import {Observable} from 'rxjs';
import {jwtDecode} from "jwt-decode";

@Injectable({
    providedIn: 'root'
})
export class AuthGuard  {
    constructor(private router: Router) {
    }

    canActivate(next: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const auth = localStorage.getItem('token');

        if (auth !== null && auth && !this.isSessionExpired(auth)) {
            return true;
        } else {
            localStorage.clear();
            this.router.navigate(['/auth/login']);
            return false;
        }
    }

    isSessionExpired(auth: string): boolean {

        if (!auth){
            return true;
        }
        const tokenDetail = jwtDecode(auth);
        const expires = String(tokenDetail.exp);
        if (!expires) {
            return true;
        }
        const timestamp = parseInt(expires) * 1000; // Convert to milliseconds

        const timestampDate = new Date(timestamp);
        const currentTime = new Date().getTime();

        if (currentTime <= Number(timestampDate)) {
            return false;
        }
        return true;
    }
}

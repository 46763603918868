import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeFormat'
})
export class TimeFormatPipe implements PipeTransform {
  transform(value: string): string | null {
    if (!value) {
      return null;
    }

    // Split the time string (e.g., "16:30:00") into hours, minutes, seconds
    const [hours, minutes, seconds] = value.split(':').map(Number);

    // Convert to 12-hour format
    const period = hours >= 12 ? 'PM' : 'AM';
    const hoursIn12HrFormat = hours % 12 || 12; // Convert 0 to 12 for midnight

    // Return the formatted time string
    return `${this.padZero(hoursIn12HrFormat)}:${this.padZero(minutes)} ${period}`;
  }

  // Utility function to add leading zero if needed
  private padZero(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }
}

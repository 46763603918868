<div class="main">
    <!-- <div *ngIf="data?.type == 'multiple'" class="item">
        Total Selected Customer: {{ data?.selectedSchedule }}
    </div> -->
    <div class="item" *ngIf="data?.type != 'multiple' ">
        <span class="label">Cancelled by:</span>
        <span class="detail-val">
            <nz-select nzAllowClear nzPlaceHolder="Select..."
            name="cancelledBy" [(ngModel)]="model.cancelledBy"
            >
                <nz-option nzLabel="CUSTOMER" nzValue="CANCELED_BY_CUSTOMER"></nz-option>
                <nz-option nzLabel="ADMIN" nzValue="CANCELED_BY_ADMIN"></nz-option>
            </nz-select>
        </span>
    </div>
    <div class="item">
        <span class="label">Cancelled Reason:</span>
    </div>

    <div>
        <textarea rows="4" appTrim nz-input name="cancelReason" [(ngModel)]="model.cancelReason"></textarea>
    </div>
</div>

<div *nzModalFooter>
    <button nz-button nzType="primary" (click)="destroyModal()">Confirm</button>
</div>
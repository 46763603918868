import { Component, Inject } from '@angular/core';
import { NzModalRef, NZ_MODAL_DATA, NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-refund-detail-dialog',
  templateUrl: './refund-detail-dialog.component.html',
  styleUrls: ['./refund-detail-dialog.component.css']
})
export class RefundDetailDialogComponent {
  constructor(
    private modal: NzModalRef,
    @Inject(NZ_MODAL_DATA) public data: any,
    public modalService: NzModalService
  ) {
  }

  destroyModal(): void {
    this.modal.destroy(true);
  }
}

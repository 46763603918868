import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GenericService } from 'src/app/@Core/services/generic.service';
import { configApiUrl } from 'src/app/app-const';

@Injectable({
  providedIn: 'root',
})
export class DiscountService extends GenericService<any> {
  constructor(http: HttpClient, router: Router) {
    super(http, configApiUrl.DISCOUNT_API, router);
  }
}

<div class="container-fluid p-h-0 p-v-20 h-100 bg" style="background-image: url('assets/images/others/login-3.png')">
    <div class="d-flex flex-column justify-content-between h-100">
        <div class="d-none d-md-block"></div>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-7 col-lg-5 m-h-auto">
                    <nz-card class="m-b-100 shadow-lg">
                        <div class="d-flex align-items-center justify-content-between m-b-30">
                            <img style="height: 60px;" class="img-fluid" alt="" src="assets/images/logo/logo.png">
                            <h2 class="m-b-0">Create New Password</h2>
                        </div>
                        <form nz-form [formGroup]="loginForm" nzLayout="vertical" class="login-form"
                            (ngSubmit)="submitForm()">
                            <nz-form-item class="relative">
                                <nz-form-label nzRequired nzFor="password">New Password</nz-form-label>
                                <nz-form-control [nzErrorTip]="newPassword">
                                    <nz-input-group [nzPrefix]="prefixLock" [nzSuffix]="suffixTemplate">
                                        <input appTrim [type]="passwordVisible ? 'text' : 'password'" nz-input formControlName="password"
                                            placeholder="Please enter your new password!" (blur)="loginForm.controls['password'].markAsTouched()">
                                    </nz-input-group>
                                    <ng-template #newPassword let-control>
                                        <ng-container *ngIf="loginForm.controls['password'].touched">
                                            <ng-container *ngIf="control.hasError('required')">Please enter your new password!</ng-container>
                                            <ng-container *ngIf="control.hasError('pattern')">
                                                <ul style="padding-left: 20px;">
                                                    <li>Password must be at least 8 characters long</li>
                                                    <li>Contain at least one uppercase letter</li>
                                                    <li>Contain at least one number</li>
                                                    <li>Contain at least one special character</li>
                                                </ul>
                                            </ng-container>
                                        </ng-container>
                                    </ng-template>
                                   
                                </nz-form-control>
                            </nz-form-item>
                            <nz-form-item class="relative">
                                <nz-form-label nzRequired nzFor="rePassword">Confirm New Password</nz-form-label>
                                <nz-form-control [nzErrorTip]="rePasswordError" nzErrorTip="">
                                    <nz-input-group [nzPrefix]="prefixLock" [nzSuffix]="suffixTemplate1">
                                        <input  [type]="passwordVisible1 ? 'text' : 'password'" nz-input formControlName="rePassword"
                                            placeholder="Please re-enter your new password!">
                                            
                                    </nz-input-group>
                                    <ng-template #rePasswordError let-control>
                                        <ng-container *ngIf="control.hasError('mustMatch')">Password doesnot match.</ng-container>
                                        <ng-container *ngIf="control.hasError('required')">Please re-enter your new password!</ng-container>
                                       
                                    </ng-template>
                                </nz-form-control>
                            </nz-form-item>
                            <nz-form-item>
                                <nz-form-control>
                                    <div class="d-flex align-items-center justify-content-end">
                                        <button [disabled]="!loginForm.valid" nz-button class="login-form-button"
                                            [nzType]="'primary'">Create New Password</button>
                                    </div>
                                </nz-form-control>
                            </nz-form-item>
                        </form>
                        <ng-template #prefixUser><i nz-icon nzType="user"></i></ng-template>
                        <ng-template #prefixLock><i nz-icon nzType="lock"></i></ng-template>
                        <ng-template #suffixTemplate>
                            <i nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'" (click)="passwordVisible = !passwordVisible"></i>
                          </ng-template>
                          <ng-template #suffixTemplate1>
                            <i nz-icon [nzType]="passwordVisible1 ? 'eye-invisible' : 'eye'" (click)="passwordVisible1 = !passwordVisible1"></i>
                          </ng-template>
                    </nz-card>
                </div>
            </div>
        </div>
    </div>
</div>
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GenericService } from 'src/app/@Core/services/generic.service';
import { configApiUrl } from 'src/app/app-const';

@Injectable({
  providedIn: 'root',
})
export class UserService extends GenericService<any> {
  constructor(http: HttpClient, router: Router) {
    super(http, configApiUrl.USER_API, router);
  }


  public verifyPasswordToken(tokenId): Observable<any> {
    return this.http.get(configApiUrl.PASSWORDTOKEN_API + tokenId, {observe: 'response'}).pipe(
        map((res: any) => {
            this.setUpdatedHeader(res);
            return res.body;
        }),
        catchError((error) => this.handleError(error))
    );
}
public updatePassword(data): Observable<any> {
  return this.http.post(configApiUrl.USER_API + '/updatePassword',data, {observe: 'response'}).pipe(
      map((res: any) => {
          this.setUpdatedHeader(res);
          return res.body;
      }),
      catchError((error) => this.handleError(error))
  );
}

  public changePassword(data): Observable<any> {
    return this.http.put(configApiUrl.USER_API + '/changePassword',data, {observe: 'response'}).pipe(
        map((res: any) => {
          this.setUpdatedHeader(res);
          return res.body;
        }),
        catchError((error) => this.handleError(error))
    );
  }


public userUnlock(id): Observable<any> {
  return this.http.put(configApiUrl.USER_API + '/unlock/'+id, null, {observe: 'response'}).pipe(
      map((res: any) => {
          this.setUpdatedHeader(res);
          return res.body;
      }),
      catchError((error) => this.handleError(error))
  );
}
public resendInvitation(id): Observable<any> {
  return this.http.put(configApiUrl.USER_API + '/resendInvitation/'+id, null, {observe: 'response'}).pipe(
      map((res: any) => {
          this.setUpdatedHeader(res);
          return res.body;
      }),
      catchError((error) => this.handleError(error))
  );
}
public userRemove(id): Observable<any> {
  return this.http.put(configApiUrl.USER_API + '/remove/'+id, null, {observe: 'response'}).pipe(
      map((res: any) => {
          this.setUpdatedHeader(res);
          return res.body;
      }),
      catchError((error) => this.handleError(error))
  );
}
public userLock(id): Observable<any> {
  return this.http.put(configApiUrl.USER_API + '/lock/'+id, null, {observe: 'response'}).pipe(
      map((res: any) => {
          this.setUpdatedHeader(res);
          return res.body;
      }),
      catchError((error) => this.handleError(error))
  );
}
public userProfile(): Observable<any> {
  return this.http.get(configApiUrl.USER_API + '/profile',  {observe: 'response'}).pipe(
      map((res: any) => {
          this.setUpdatedHeader(res);
          return res.body;
      }),
      catchError((error) => this.handleError(error))
  );
}




}

import {Routes} from '@angular/router';

export const CommonLayout_ROUTES: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../pages/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'user',
    loadChildren: () => import('../../pages/user/user.module').then(m => m.UserModule),
  },
  {
    path: 'agent',
    loadChildren: () => import('../../pages/agent/agent.module').then(m => m.AgentModule),
  },
  {
    path: 'category',
    loadChildren: () => import('../../pages/setting/category/category.module').then(m => m.CategoryModule),
  },
  {
    path: 'default-commission',
    loadChildren: () => import('../../pages/setting/default-commission/default-commission.module').then(m => m.DefaultCommissionModule),
  },
  {
    path: 'default-agent-credit',
    loadChildren: () => import('../../pages/setting/default-agent-credit/default-agent-credit.module').then(m => m.DefaulAgentCreditModule),
  },
  {
    path: 'pricing',
    loadChildren: () => import('../../pages/setting/pricing/pricing.module').then(m => m.PricingModule),
  },
  {
    path: 'discount',
    loadChildren: () => import('../../pages/setting/discount/discount.module').then(m => m.DiscountModule),
  },
  {
    path: 'schedule',
    loadChildren: () => import('../../pages/setting/schedule/schedule.module').then(m => m.ScheduleModule),
  },
  {
    path: 'service-halt',
    loadChildren: () => import('../../pages/setting/service-halt/service-halt.module').then(m => m.ServiceHaltModule),
  },
  {
    path: 'booking',
    loadComponent: () => import('../../pages/booking/booking.component').then(m => m.BookingComponent),
  },
  {
    path: 'schedule',
    loadChildren: () => import('../../pages/schedule/schedule.module').then(m => m.ScheduleModule)
  },
  {
    path: 'report',
    loadChildren: () => import('../../pages/report/report.module').then(m => m.ReportModule)
  }
];

<div class="main">
    <div class="item">
        <span class="col-sm-5 label">Total Amount:</span>
        <span class=" label"> Rs. {{ data?.data.totalAmount }}</span>
    </div>
    <div class="item">
        <span class="col-sm-5 label">Agent Commission:</span>
        <span class=" label"> Rs. {{ data?.data.agentCommission }}</span>
    </div>
    <div class="item">
        <span class="col-sm-5 label">Discount Amount:</span>
        <span class="label"> Rs. {{ data?.data.discountAmount }}</span>
    </div>
    <div class="item">
        <span class="col-sm-5 label">Net Amount:</span>
        <span class="label"> Rs. {{ data?.data.netAmount }}</span>
    </div>
    <hr>
    <div class="item">
        <span class="col-sm-5 label">Paid Amount:</span>
        <span class="label"> Rs. {{ data?.data.paidAmount }}</span>
    </div>
    <div *ngIf="data?.data.refundPercentage" class="item">
        <span class="col-sm-5 label">Refund Percentage:</span>
        <span class="label"> {{ data?.data.refundPercentage }}%</span>
    </div>
    <div *ngIf="data?.data.refundAmount" class="item">
        <span class="col-sm-5 label">Refund Amount:</span>
        <span class="label"> Rs. {{ data?.data.refundAmount }}</span>
    </div>

    <div *ngIf="data?.data.refundedAmount" class="item">
        <span class="col-sm-5 label">Refunded Amount:</span>
        <span class="label"> Rs. {{ data?.data.refundedAmount }}</span>
    </div>
    <div *ngIf="data?.data.payableAmount" class="item">
        <span class="col-sm-5 label">Payable Amount:</span>
        <span class="label"> Rs. {{ data?.data.payableAmount }}</span>
    </div>
</div>

<div *nzModalFooter>
    <button nz-button nzType="primary" (click)="destroyModal()">Apply</button>
</div>
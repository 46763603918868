import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyNepali'
})
export class CurrencyNepaliPipe implements PipeTransform {

  transform(value: number, symbol: string = '₹'): string {
    if (!value && value !== 0) { return ''; }

    // Format the number with commas for thousands
    const formattedValue = new Intl.NumberFormat('en-IN').format(value);

    return `${symbol} ${formattedValue}`;
  }

}

import {Component, EventEmitter, Inject, Output} from '@angular/core';
import {NZ_MODAL_DATA, NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import {NzMessageService} from 'ng-zorro-antd/message';
import {BookingService} from '../service/booking.service';
import {UserService} from '../../user/service/user.service';
import {jwtDecode} from 'jwt-decode';
import {
  DetailDialogComponent
} from '../dialog-schedule-detail/detail-dialog/detail-dialog.component';
import {
  ConfirmBookingComponent
} from '../dialog-schedule-detail/confirm-booking/confirm-booking.component';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {
  RefundDetailDialogComponent
} from '../dialog-schedule-detail/refund-detail-dialog/refund-detail-dialog.component';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-view-booking',
  templateUrl: './view-booking.component.html',
  styleUrls: ['./view-booking.component.css']
})
export class ViewBookingComponent {
  @Output() actionOccurred = new EventEmitter<boolean>();
  currencySymbol = 'Rs';
  image: any = '';
  tokenDetail: any = jwtDecode(localStorage.getItem('token'));
  paymentAuthorizedBy: string;
  discountAuthorizedBy: string;
  cancelObj: any;
  isEdit = false;
  imageSize: any;
  imageFileName = '';
  customerDoc: any;
  updateModel = {
    pickUpLocation: null,
    weight: 0
  };

  constructor(
      private modal: NzModalRef,
      @Inject(NZ_MODAL_DATA) public data: any,
      private message: NzMessageService,
      private service: BookingService,
      private userService: UserService,
      public modalService: NzModalService,
      private notification: NzNotificationService,
      private loadingService: NgxSpinnerService,
  ) {

    if (this.data?.bookingData?.paymentDetail?.exchangeRateDetail?.currency === 'NEPALI_RUPEES'){
      this.currencySymbol = 'Rs';
    }else{
      this.currencySymbol = '$';
    }
    if (this.data?.bookingData?.customerDetail?.registrationDocumentId) {
      this.service.getDocumentById(this.data?.bookingData?.customerDetail?.registrationDocumentId)
      .subscribe((resImg) => {
        this.service.convertBlobToBase64(resImg).then(base64 => {
          this.image = base64;
        });
      });
    }
    if (this.data?.bookingData?.paymentDetail?.discountAuthorizedBy){
      this.userService.getById(this.data?.bookingData?.paymentDetail?.discountAuthorizedBy)
      .subscribe((user) =>
      {
        const firstName = user?.data?.firstName;
        const lastName = user?.data?.lastName;
        this.discountAuthorizedBy = firstName + ' ' + lastName;
      });
    }
    if (this.data?.bookingData?.paymentDetail?.paymentAuthorizedBy){
      this.userService.getById(this.data?.bookingData?.paymentDetail?.paymentAuthorizedBy)
      .subscribe((user) =>
      {
        const firstName = user?.data?.firstName;
        const lastName = user?.data?.lastName;
        this.paymentAuthorizedBy = firstName + ' ' + lastName;
      });
    }

    this.setUpdateDetails();
  }

  setUpdateDetails(): void{
    this.updateModel.weight = this.data.bookingData?.customerDetail?.weight;
    this.updateModel.pickUpLocation = this.data.bookingData?.customerDetail?.pickUpLocation;
  }

  getBookingStatusTagColor(status: string): string {
    switch (status) {
      case 'BOOKED':
        return 'green';
      default:
        return 'red';
    }
  }

  getPaymentStatusTagColor(status: string): string{
    switch (status) {
      case 'PAID':
        return 'green';
      case 'UNPAID':
        return 'red';
      default:
        return 'orange';
    }
  }

  destroyModal(): void {
    this.modal.destroy();
  }

  cancelBooking(type: string, id: any = null): void {
    const modalRef: NzModalRef = this.modalService.create({
      nzTitle: 'Schedule Detail',
      nzWidth: '500px',
      nzData: {
        type: 'single',
        selectedSchedule: 0
      },
      nzContent: DetailDialogComponent
    });

    modalRef.afterClose.subscribe(result => {
      if (result) {
        this.cancelObj = result;
        this.cancelConfirm(type, id);
      }
    });
  }

  cancelConfirm(type: any, id: any): void {
    const modalRef: NzModalRef = this.modalService.confirm({
      nzTitle: 'Warning',
      nzWidth: '500px',
      nzData: {
        title: 'Are you Sure? You want to cancel Schedule'
      },
      nzContent: ConfirmBookingComponent,
      nzOnOk: (val: any) => {

        const params = {
          cancelType: this.cancelObj.cancelledBy,
          cancelReason: this.cancelObj.cancelReason,
        };

        this.service.cancelBookingDetail(params, id)
        .subscribe(
            (resp: any) => {
              if (resp?.data) {
                this.notification.success('success', resp.message);
                // this.getDetail();
                // parentModalRef.destroy(true);
                this.closeModelAndEmitEvent();
              }
            },
            err => {
              this.notification.error('error', err.error.message);
            }
        );
      },
      nzOnCancel: () => {
        console.log('Delete action canceled');
      }
    });
  }

  payDetail(id): void {
    this.service.getPaymentDetail(id)
    .subscribe(
        (resp: any) => {
          if (resp?.data) {
            const modalRef: NzModalRef = this.modalService.create({
              nzTitle: 'Payment Detail',
              nzWidth: '500px',
              nzData: {
                data: resp.data
              },
              nzContent: RefundDetailDialogComponent
            });

            modalRef.afterClose.subscribe(result => {
              // tslint:disable-next-line:triple-equals
              if (result == true) {
                this.refundConfirm(id, 'payment');
              }
            });
          }
        },
        err => {
          this.notification.error('error', err.error.message);
        }
    );
  }

  refundDetail(id): void {
    this.service.getRefundDetail(id)
    .subscribe(
        (resp: any) => {
          if (resp?.data) {
            const modalRef: NzModalRef = this.modalService.create({
              nzTitle: 'Refund',
              nzWidth: '500px',
              nzData: {
                data: resp.data
              },
              nzContent: RefundDetailDialogComponent
            });

            modalRef.afterClose.subscribe(result => {
              if (result == true) {
                this.refundConfirm(id);
              }
            });
          }
        },
        err => {
          this.notification.error('error', err.error.message);
        }
    );
  }

  refundConfirm(id: any, payment: any = null): void {
    let title =  'Are you Sure? You want to Refund';
    if (payment){
      title = 'Are you Sure? You want to mark as Paid';
    }
    const modalRef: NzModalRef = this.modalService.confirm({
      nzTitle: 'Warning',
      nzWidth: '500px',

      nzData: {
        title
      },
      nzContent: ConfirmBookingComponent,
      nzOnOk: (val: any) => {

        if (payment) {
          this.service.getMarkAsPaid(id)
          .subscribe(
              (resp: any) => {
                if (resp?.data) {
                  this.notification.success('success', resp.message);
                  this.closeModelAndEmitEvent();
                }
              },
              err => {
                this.notification.error('error', err.error.message);
              }
          );
        }

        else {

          this.service.getRefund(id)
          .subscribe(
              (resp: any) => {
                if (resp?.data) {
                  this.notification.success('success', resp.message);
                  this.closeModelAndEmitEvent();
                }
              },
              err => {
                this.notification.error('error', err.error.message);
              }
          );
        }

      },
      nzOnCancel: () => {
        console.log('Delete action canceled');
      }
    });
  }

  closeModelAndEmitEvent(): void{
    this.destroyModal();
    this.actionOccurred.emit(true);
  }

  editBooking(): void {
    this.isEdit = true;
  }

  cancelEditing(): void {
    this.isEdit = false;
    this.setUpdateDetails();
  }

  onFileChange(event: any): void {
    let targetFiles;
    if (event.target?.files?.length) {
      targetFiles = event.target.files;
    } else if (event.dataTransfer.files) {
      targetFiles = event.dataTransfer.files;
    } else {
      return;
    }
    if (targetFiles[0].size <= 500000) {
      this.imageSize = targetFiles[0].size;
      this.imageFileName = targetFiles[0].name;
      this.customerDoc = targetFiles[0];

      const reader = new FileReader();
      reader.readAsDataURL(targetFiles[0]); // read file as data url
      let img;
      reader.onload = (onloadEvent) => {
        // called once readAsDataURL is completed
        img = onloadEvent.target?.result;
        this.image = img;
      };

      let fd = new FormData();
      fd.append('file', this.customerDoc);
      this.loadingService.show();
      this.service.updateDocument(fd, this.data?.bookingData?.customerDetail?.registrationDocumentId).subscribe(
          (data) => {
            this.loadingService.hide();
          }, (error) => {
            this.notification.error('Error', error.error.message);
            this.loadingService.hide();
          }
      );
    } else {
      this.notification.error('Error', 'File size should be less than 500 Kb');
    }
  }

  updateBooking(): void {
    console.log(this.data);
    this.loadingService.show();
    this.service.updateBooking(this.updateModel, this.data.bookingData.id).subscribe(
        (resp: any) => {
          this.notification.success('Success', resp.message);
          this.loadingService.hide();
          this.closeModelAndEmitEvent();
        },
        (err) => {
          this.notification.error('Error', err.error.message);
          this.loadingService.hide();
        }
    );
  }
}

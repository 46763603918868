import { Component } from '@angular/core';
import { ThemeConstantService } from './shared/services/theme-constant.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {

    constructor(public themeService: ThemeConstantService){
        this.themeService.changeHeaderColor("magenta");
    }
}
